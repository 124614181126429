




























































































































import { Vue, Component, Prop } from 'vue-property-decorator';
import { UserService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'AcceptInvitationForm',
})
export default class AcceptInvitationForm extends Vue {
  @Prop({ required: true }) private name!: string;

  @Prop({ required: true }) private invitationToken!: string;

  private readonly logger: Logger = new Logger({ context: 'AcceptInvitationForm' });

  private userService = UserService.getInstance();

  private instructionMessage = 'Please confirm your name, set a new password and click "ACTIVATE" to activate your account:';

  private missingTokenMessage = 'Please use the "Accept invitation" link in the invite email sent to you';

  private form = {
    name: '',
    password: '',
    passwordConfirmation: '',
    valid: false,
    rules: {
      required: (value: string) => !!value || 'Required',
      counter: (value: string) => value.length >= 6 || 'Minimum 6 characters',
    },
  };

  private applicationName = '';

  private error = '';

  private isSnackbarVisible = false;

  private loading = false;

  private isSuccessAlertVisible = false;

  private strings = {
    success: 'Account activated! Redirecting to login...',
  };

  private isSubmitted = false;

  public mounted() {
    this.init();
  }

  /**
   * When enter key pressed in reset password form: focus password input, or register.
   * @e KeyboardEvent
   */
  protected onKeyup(e: KeyboardEvent) {
    if (e.key !== 'Enter') {
      return;
    }
    if ((e.target as HTMLElement).id === 'passwordConfirmation'
            && !this.loading
            && !this.isSubmitted) {
      this.acceptInvitation();
      return;
    }
    (this.$refs.password as any).focus(); // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  private async init() {
    const config = await Utility.fetchConfig();
    this.applicationName = config.portal.name;
    this.form.name = this.name;
  }

  /**
   * reset password and navigate to login.
   */
  private async acceptInvitation() {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    try {
      const config = await Utility.fetchConfig();
      const { access_token } = config.portal;
      await this.userService.api.acceptInvitation({
        access_token,
        order_form_user: {
          invitation_token: this.invitationToken,
          name: this.form.name,
          password: this.form.password,
          password_confirmation: this.form.passwordConfirmation,
        },
      });
      this.isSuccessAlertVisible = true;
      this.loading = false;
      this.isSubmitted = true;
      setTimeout(() => this.$router.push({ name: 'login' }), 3000);
    } catch (error) {
      this.logger.error(error);
      this.error = error?.response?.data?.message ?? error.message;
      this.isSnackbarVisible = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
}
