



















































































































































import { Vue, Component } from 'vue-property-decorator';
import { AuthService } from '@/services';
import { Logger } from '@/tools/Logger';
import PopoutButton from '@/views/Dashboard/Docs/components/PopoutButton.vue';
import { Utility } from '@/tools/Utility';
import { SentryService } from '@/services/SentryService';

@Component({
  name: 'LoginForm',
  components: {
    PopoutButton,
  },
})
export default class LoginForm extends Vue {
  /**
   * Application Name
   */
  protected applicationName = '';

  /**
   * Error
   */
  protected error = '';

  /**
   * Is Snackbar Visible
   */
  protected isSnackbarVisible = false;

  /**
   * Is Logging In
   */
  protected isLoggingIn = false;

  /**
   * Resolved router link to open in popout
   */
  protected popoutHref = '';

  /**
   * User Service for handling login requests and auth storage
   */
  protected readonly authService: AuthService = AuthService.getInstance();

  protected readonly sentryService: SentryService = SentryService.getInstance();

  protected readonly logger: Logger = new Logger({ context: 'LoginForm' });

  /**
   * Form object for handling credentials
   */
  protected readonly form = {
    email: '',
    password: '',
    valid: false,
    rules: {
      required: (value: string) => !!value || 'Required.',
      counter: (value: string) => value.length >= 5 || 'Invalid password',
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail';
      },
    },
  };

  public mounted() {
    this.init();
  }

  public async init() {
    const config = await Utility.fetchConfig();
    this.applicationName = config.portal.name;

    const resolved = this.$router.resolve({
      name: 'public-docs-md',
      params: {
        doc: 'user-manual',
      },
      query: {
        popped: 'yes',
      },
    });
    this.popoutHref = resolved.href;
  }

  /**
   * When enter key pressed in login form: focus password input, or login
   */
  protected onKeyup(e: KeyboardEvent) {
    if (e.key !== 'Enter') {
      return;
    }
    if ((e.target as HTMLElement).id === 'password') {
      this.login();
      return;
    }
    (this.$refs.password as any).focus(); // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  /**
   * Login and navigate to home
   */
  protected async login() {
    if (!this.form.valid) {
      return;
    }

    this.isLoggingIn = true;

    try {
      const user = await this.authService.login(this.form.email, this.form.password);

      // Using the new session, provide the user object to our Sentry
      // configuration to ensure we've assembled a useful context about
      // the user for when errors occur.
      this.sentryService.configureUser(user);

      this.$router.push({ name: 'orders-list' });
    } catch (error) {
      if (error.config?.data) {
        const cleaned = JSON.parse(error.config.data);
        if (cleaned.user_login?.password) delete cleaned.user_login.password;
        error.config.data = JSON.stringify(cleaned);
      }
      this.logger.error(error);
      this.error = error.message;
      this.isSnackbarVisible = true;
      setTimeout(() => {
        this.isLoggingIn = false;
      }, 1000);
    }
  }
}
