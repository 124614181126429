


















































import { Vue, Component } from 'vue-property-decorator';
import { AuthService } from '@/services';
import { SentryService } from '@/services/SentryService';

@Component({
  name: 'Logout',
})
export class Logout extends Vue {
  private readonly authService = AuthService.getInstance();

  private readonly sentryService = SentryService.getInstance();

  public mounted() {
    this.logout();
  }

  private async logout() {
    this.sentryService.configureUser(null);
    await this.authService.logout();
    await this.$router.push({ name: 'login' });
  }
}

export default Logout;
