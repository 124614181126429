



















import { Vue, Component, Prop } from 'vue-property-decorator';
import { AuthService } from '@/services';
import { Logger } from '@/tools/Logger';

@Component({
  name: 'ConfirmRegistration',
})
export default class ConfirmationRegistration extends Vue {
  @Prop({ required: true })
  private readonly confirmationToken!: string;

  /**
   * User Service for handling login requests and auth storage
   */
  private readonly authService: AuthService = AuthService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ConfirmationRegistration' });

  public created() {
    this.validateConfirmationToken();
  }

  /**
   * Validate confirmation token and navigate to home.
   */
  private async validateConfirmationToken() {
    try {
      await this.authService.confirmRegistrationThenlogin(this.confirmationToken);
      this.$router.push({ name: 'orders-list' });
    } catch (error) {
      this.logger.error(error);
      this.$router.push({ name: 'login' });
    }
  }
}
