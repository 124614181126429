

































































































import { Vue, Component } from 'vue-property-decorator';
import { AuthService } from '@/services';
import { Logger } from '@/tools/Logger';
import { Utility } from '@/tools/Utility';

@Component({
  name: 'ForgotPasswordForm',
})
export default class ForgotPasswordForm extends Vue {
  protected instructionMessage = 'Enter your account\'s email address below and click RESET to reset your password:';

  protected applicationName = '';

  protected error = '';

  protected isSnackbarVisible = false;

  protected isSuccessAlertVisible = false;

  protected strings = {
    success: 'Please check your inbox (or spam folder) for a password reset email',
  };

  protected isSubmitted = false;

  private readonly authService = AuthService.getInstance();

  private readonly logger: Logger = new Logger({ context: 'ForgotPasswordForm' });

  private form = {
    email: '',
    valid: false,
    rules: {
      required: (value: string) => !!value || 'Required',
      email: (value: string) => {
        const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return pattern.test(value) || 'Invalid e-mail';
      },
    },
  };

  private loading = false;

  public mounted() {
    this.init();
  }

  /**
   * When enter key pressed in forgot password form:
   * focus email input, or send reset password email.
   * @e KeyboardEvent
   */
  protected onKeyup(e: KeyboardEvent) {
    if (e.key !== 'Enter') {
      return;
    }
    if ((e.target as HTMLElement).id === 'email'
        && !this.loading
        && !this.isSubmitted) {
      this.sendPasswordResetEmail();
      return;
    }
    (this.$refs.email as any).focus(); // eslint-disable-line @typescript-eslint/no-explicit-any
  }

  private async init() {
    const config = await Utility.fetchConfig();
    this.applicationName = config.portal.name;
  }

  /**
   * send reset password email.
   */
  private async sendPasswordResetEmail() {
    if (!this.form.valid) {
      return;
    }

    this.loading = true;
    try {
      await this.authService.sendPasswordResetEmail(this.form.email);
      this.isSuccessAlertVisible = true;
      this.loading = false;
      this.isSubmitted = true;
    } catch (error) {
      this.logger.error(error);
      this.error = error?.response?.data?.message ?? error.message;
      this.isSnackbarVisible = true;
      setTimeout(() => {
        this.loading = false;
      }, 1000);
    }
  }
}
